import { React } from "react";
import Slider from "react-slick";

function FeaturedProjects() {
  return (
    <section>
      <h2 className="mb-5 text-center uppercase font-bold font-[Aquarium] text-[clamp(2rem,3vw,4rem)]">
        future projects
      </h2>
      <div className="bg-center bg-fixed bg-cover bg-no-repeat flex items-center justify-center py-10  bg-[linear-gradient(rgba(4,9,30,0.3),rgba(4,9,30,0.5)),url('~/public/images/featured-projects-bg.webp')]">
        <Slider
          slidesToScroll={1}
          slidesToShow={1}
          speed={500}
          infinite={true}
          arrows={false}
          autoplay={true}
          autoplaySpeed={3000}
          className="max-w-[90%] flex items-center justify-center"
        >
          <div className="px-5">
            <div className="fetured-project__slider-item">
              <div className="flex flex-col lg:w-[70%] px-5">
                <h2 className="text-center text-black uppercase font-bold text-[clamp(2rem,3vw,3rem)]  font-[Aquarium] my-5">
                  al zahraa university hospital
                </h2>
                <p className="text-gray-500  max-lg:text-center text-[18px] max-lg:text-[12px] text-center">
                  This project involves the renovation and upgrading of the old
                  building of *Al-Zahraa University Hospital* to enhance its
                  efficiency and modernize its infrastructure. The scope of work
                  includes various refurbishment activities aimed at improving
                  the hospital's operational capacity and ensuring it meets
                  current healthcare standards.
                </p>
                <a
                  className="text-center font-semibold capitalize mx-auto w-32 py-2 my-6 block bg-white border-black hover:invert rounded-lg border-2 transition-all"
                  href="/projects/healthcare/al-zahraa-university-hospital"
                >
                  View more
                </a>
              </div>
              <div className="flex items-center h-[580px] w-full max-lg:hidden">
                <img
                  src="/images/projects/zhu1.webp"
                  alt="featured project"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="px-5">
            <div className="fetured-project__slider-item">
              <div className="flex flex-col lg:w-[70%] px-5">
                <h2 className="text-center text-black uppercase font-bold text-[clamp(2rem,3vw,3rem)]  font-[Aquarium] my-5">
                  construction of main warehouses
                </h2>
                <p className="text-gray-500  max-lg:text-center text-[18px] max-lg:text-[12px] text-center">
                  This project involves the construction of the main warehouses,
                  a modular unit, and a clinic building on a plot of 8,000
                  square meters located in Salama Land, as part of the "Ahlina"
                  Lands Development Project in the Cairo Governorate. The
                  project aims to provide key infrastructure and services for
                  the local community, facilitating urban development and
                  improving living standards.
                </p>
                <a
                  className="text-center font-semibold capitalize mx-auto w-32 py-2 my-6 block bg-white border-black hover:invert rounded-lg border-2 transition-all"
                  href="/projects/healthcare/construction-of-main-warehouses"
                >
                  View more
                </a>
              </div>
              <div className="flex items-center h-[580px] w-full max-lg:hidden">
                <img
                  src="/images/projects/construction-of-main-warehouses01.webp"
                  alt="featured project"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="px-5">
            <div className="fetured-project__slider-item">
              <div className="flex flex-col lg:w-[70%] px-5">
                <h2 className="text-center text-black uppercase font-bold text-[clamp(2rem,3vw,3rem)]  font-[Aquarium] my-5">
                  Food trip factory
                </h2>
                <p className="text-gray-500  max-lg:text-center text-[18px] max-lg:text-[12px] text-center">
                  This project involves the design and supervision of the
                  construction of a factory on land plot number 276, located in
                  the A5 area of the 10th of Ramadan City. The factory will
                  cover an area of 3,700 square meters and is owned by Food Trip
                  Company for Food Industries. Our goal is to ensure a
                  high-quality and efficient construction process to enhance
                  production capabilities.
                </p>
                <a
                  className="text-center font-semibold capitalize mx-auto w-32 py-2 my-6 block bg-white border-black hover:invert rounded-lg border-2 transition-all"
                  href="/projects/healthcare/construction-of-main-warehouses"
                >
                  View more
                </a>
              </div>
              <div className="flex items-center h-[580px] w-full max-lg:hidden">
                <img
                  src="/images/projects/food-trip-factory01.webp"
                  alt="featured project"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}
export default FeaturedProjects;
